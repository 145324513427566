import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DropdownMenu from "./Dropdown/DropdownMenu";
import logo from "../asset/images/logo.png";

const Footer = () => {
  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [closeOffcanvas, setCloseOffcanvas] = useState(false);

  const handleOffcanvasToggle = () => {
    setOffcanvasOpen(!isOffcanvasOpen);
  };

  const handleLinkClick = () => {
    if (isOffcanvasOpen) {
      setCloseOffcanvas(true);
      setTimeout(() => {
        setCloseOffcanvas(false);
        setOffcanvasOpen(false);
      }, 1000);
    }
  };

  return (
    <>
      <footer className="footer-bg text-light py-3 sans-serif-font">
        <section className="">
          <div className="container  text-md-start mt-5">
            {/* <div className="row">
              <div className=" col-3 text-uppercase fw-bold">
              </div>
            </div> */}
            <div className="row my-4">
              <div className="col-md-6 mb-4 mx-auto about-aruwa-section ">
                <h3 className="yellow-text">About ARUWA</h3>
                <p>
                  Aruwa brings dreams to life. We are one of the leading interior design companies in the city of Jaipur. We specialize in designing beautiful living and working spaces for our customers. Whether you are looking for a comfortable bedroom, functional kitchen, stylish living room or professional office, Aruwa has the know-how and experience to turn your vision into reality.
                </p>

                <div className="d-flex bottom-social-icons">

                  <a
                    className="text-white"
                    href="https://www.facebook.com/aruwa.in"
                    target="blank"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    className="text-white"
                    href="https://twitter.com/AruwaGroup"
                    target="blank"
                  >
                    {" "}
                    <i className="fab fa-x-twitter"></i>
                  </a>
                  <a
                    className="text-white"
                    href="https://www.instagram.com/aruwa.in/"
                    target="blank"
                  >
                    {" "}
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    className="text-white"
                    href="https://www.linkedin.com/company/aruwa-interiors/"
                    target="blank"
                  >
                    {" "}
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a
                    className="text-white"
                    href="https://www.youtube.com/@aruwainteriors"
                    target="blank"
                  >
                    {" "}
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>



              <div className="col-md-3 mb-4 mx-auto ">
                <h4 className="yellow-text">Useful Links</h4>
                <p className="footer-link">
                  <Link to="/full-home-interiors" className="text-reset">
                    Full Home Interior{" "}
                    <i
                      className=" pe-2  fa-solid fa-angles-right"
                      style={{ fontSize: "12px" }}
                    ></i>
                  </Link>
                </p>
                <p className="footer-link">
                  <Link to="/modular-interior-solution" className="text-reset">
                    Modular Kitchen{" "}
                    <i
                      className=" pe-2  fa-solid fa-angles-right"
                      style={{ fontSize: "12px" }}
                    ></i>
                  </Link>
                </p>
                <p className="footer-link">
                  <Link to="/commercial-interior" className="text-reset">
                    Commercial Interior{" "}
                    <i
                      className=" pe-2  fa-solid fa-angles-right"
                      style={{ fontSize: "12px" }}
                    ></i>
                  </Link>
                </p>
                <p className="footer-link">
                  <Link to="/renovation-services-india" className="text-reset">
                    Renovation{" "}
                    <i
                      className=" pe-2  fa-solid fa-angles-right"
                      style={{ fontSize: "12px" }}
                    ></i>
                  </Link>
                </p>
                {/* <p className="footer-link">
                  <Link to="/cost" className="text-reset">Cost <i className=" pe-2  fa-solid fa-angles-right" style={{fontSize:"12px"}}></i></Link>
                </p> */}
                <p className="footer-link">
                  <Link to="/contact-us" className="text-reset">
                    {" "}
                    Contact Us{" "}
                    <i
                      className=" pe-2  fa-solid fa-angles-right"
                      style={{ fontSize: "12px" }}
                    ></i>
                  </Link>
                </p>
              </div>

              <div className="col-md-3 mb-4 mx-auto mb-md-0">
                <h5 className="yellow-text">Contact Information</h5>
                <p>
                  <a href="mailto:care@aruwa.in" className="text-white">
                    {" "}
                    <i className="fas fa-envelope me-3"></i>
                    care@aruwa.in{" "}
                  </a>
                </p>
                <p>
                  {" "}
                  <a className="text-white" href="tel:18008907797">
                    {" "}
                    <i className="fas fa-phone me-3 "></i> 18008907797
                  </a>
                </p>

              </div>
            </div>
            <div className="footer-bottom ">
              <div>
                <p>
                  All Rights Reserved @ Built-In Modular (OPC) Private Limited 2024
                </p>
              </div>
              <div className="bottom-right">
                <p className="footer-bottom-link footer-link">
                  <Link to="/terms-conditions" className="text-reset">
                    Terms of Use{" "}
                  </Link>
                </p>
                <p className="footer-bottom-link footer-link">
                  <Link to="/privacy-policy" className="text-reset">
                    Privacy Policy{" "}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>



        <div className="sticky-button p-3 d-none">
          <a
            className="btn btn-light mb-2 bg-white rounded-circle shadow"
            target="blank"
            href="https://wa.me/919773307664"
          >
            <i
              className="fa-brands fa-whatsapp  text-success"
              style={{ fontSize: 50 }}
            ></i>
          </a>
          <Link
            to={"contact-us"}
            className="btn btn-warning shadow hire-btn d-block p-2"
          >
            <span style={{ fontWeight: 800 }}>Hire Us</span>{" "}
          </Link>
        </div>
        <nav className="navbar text-center navbar-dark bg-dark fixed-bottom vw-100">
          <div className="container-fluid">
            <Link
              to={"/"}
              className={`${isActive("/")} navbar-toggler `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 22.6 25"
              >
                <g
                  id="Icon_feather-home"
                  data-name="Icon feather-home"
                  transform="translate(0.5 0.5)"
                >
                  <path
                    id="Path_54394"
                    data-name="Path 54394"
                    d="M4.5,11.4,15.3,3l10.8,8.4V24.6A2.4,2.4,0,0,1,23.7,27H6.9a2.4,2.4,0,0,1-2.4-2.4Z"
                    transform="translate(-4.5 -3)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_54395"
                    data-name="Path 54395"
                    d="M13.5,27.3V18h5.494v9.3"
                    transform="translate(-5.448 -4.25)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </g>
              </svg>
            </Link>
            <Link
              to={"/full-home-interiors"}
              className={`${isActive(
                "/full-home-interiors"
              )} navbar-toggler `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 29.663 24.718"
              >
                <g
                  id="Group_50360"
                  data-name="Group 50360"
                  transform="translate(18257 -12543)"
                >
                  <path
                    id="Path_54432"
                    data-name="Path 54432"
                    d="M38.247,29H16.494a.494.494,0,0,0-.494.494v2.966a.494.494,0,0,0,.494.494h.494V40.37a.494.494,0,0,0,.494.494h1.977v1.483a.494.494,0,0,0,.494.494h1.977a.494.494,0,0,0,.494-.494V40.865h9.887v1.483a.494.494,0,0,0,.494.494h1.977a.494.494,0,0,0,.494-.494V40.865h1.977a.494.494,0,0,0,.494-.494V32.955h.494a.494.494,0,0,0,.494-.494V29.494A.494.494,0,0,0,38.247,29ZM17.977,32.955h5.438v2.966H19.955a.494.494,0,1,0,0,.989h3.461v2.966H17.977Zm12.359,0v2.966H24.4V32.955Zm0,3.955v2.966H24.4V36.91Zm-8.9,4.944h-.989v-.989h.989Zm12.854,0H33.3v-.989h.989Zm2.472-1.977H31.325V36.91h3.461a.494.494,0,1,0,0-.989H31.325V32.955h5.438Zm.989-7.91H16.989V29.989H37.752Z"
                    transform="translate(-18266.078 12524.876)"
                    fill="#000"
                  />
                  <path
                    id="Path_54433"
                    data-name="Path 54433"
                    d="M8.921,30.73H6.944V15.9H10.4a.495.495,0,0,0,.491-.556l-.989-7.91A.494.494,0,0,0,9.416,7H3.483a.494.494,0,0,0-.491.433L2,15.343a.495.495,0,0,0,.491.556H5.955V30.73H3.977a.494.494,0,0,0,0,.989H8.921a.494.494,0,0,0,0-.989Zm-5-22.741h5.06l.865,6.921H3.054Z"
                    transform="translate(-18259 12536)"
                    fill="#000"
                  />
                  <path
                    id="Path_54434"
                    data-name="Path 54434"
                    d="M37.494,15.91h9.887a.494.494,0,0,0,.494-.494V8.494A.494.494,0,0,0,47.382,8H37.494A.494.494,0,0,0,37,8.494v6.921A.494.494,0,0,0,37.494,15.91Zm.494-.989V13.175l2.478-2.065,2.157,1.725a.494.494,0,0,0,.53.056l1.735-.867,2,1.2v1.7Zm8.9-5.932v3.082l-1.723-1.034a.5.5,0,0,0-.476-.018l-1.695.847-2.225-1.78a.494.494,0,0,0-.625.006l-2.155,1.8v-2.9Z"
                    transform="translate(-18276.697 12535.494)"
                    fill="#000"
                  />
                </g>
              </svg>
            </Link>
            <Link
              to={"/commercial-interior"}
              className={`${isActive(
                "/commercial-interior"
              )} navbar-toggler `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 22.629 25.719"
              >
                <path
                  id="Icon_awesome-building"
                  data-name="Icon awesome-building"
                  d="M21.05,23.174h-.966V1.159A1.159,1.159,0,0,0,18.925,0H2.7A1.159,1.159,0,0,0,1.545,1.159V23.174H.579A.579.579,0,0,0,0,23.753v.966H21.629v-.966A.579.579,0,0,0,21.05,23.174ZM6.18,3.669a.579.579,0,0,1,.579-.579H8.69a.579.579,0,0,1,.579.579V5.6a.579.579,0,0,1-.579.579H6.759A.579.579,0,0,1,6.18,5.6Zm0,4.635a.579.579,0,0,1,.579-.579H8.69A.579.579,0,0,1,9.27,8.3v1.931a.579.579,0,0,1-.579.579H6.759a.579.579,0,0,1-.579-.579Zm2.51,7.145H6.759a.579.579,0,0,1-.579-.579V12.939a.579.579,0,0,1,.579-.579H8.69a.579.579,0,0,1,.579.579V14.87A.579.579,0,0,1,8.69,15.449Zm3.669,7.725H9.27V19.118a.579.579,0,0,1,.579-.579H11.78a.579.579,0,0,1,.579.579Zm3.09-8.3a.579.579,0,0,1-.579.579H12.939a.579.579,0,0,1-.579-.579V12.939a.579.579,0,0,1,.579-.579H14.87a.579.579,0,0,1,.579.579Zm0-4.635a.579.579,0,0,1-.579.579H12.939a.579.579,0,0,1-.579-.579V8.3a.579.579,0,0,1,.579-.579H14.87a.579.579,0,0,1,.579.579Zm0-4.635a.579.579,0,0,1-.579.579H12.939a.579.579,0,0,1-.579-.579V3.669a.579.579,0,0,1,.579-.579H14.87a.579.579,0,0,1,.579.579Z"
                  transform="translate(0.5 0.5)"
                  fill="none"
                  stroke="#000"
                  strokeWidth="1"
                />
              </svg>
            </Link>
            <button
              className="navbar-toggler "
              type="button"
              aria-label="Toggle navigation"
              onClick={handleOffcanvasToggle}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26.334"
                height="30"
                viewBox="0 0 26.334 19"
              >
                <g
                  id="Icon_feather-menu"
                  data-name="Icon feather-menu"
                  transform="translate(-4 -8.5)"
                >
                  <path
                    id="Path_54435"
                    data-name="Path 54435"
                    d="M4.5,18H29.834"
                    transform="translate(0)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_54436"
                    data-name="Path 54436"
                    d="M4.5,9H29.834"
                    transform="translate(0)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_54437"
                    data-name="Path 54437"
                    d="M4.5,27H29.834"
                    transform="translate(0)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </g>
              </svg>
            </button>
            <div
              className={`offcanvas offcanvas-end text-bg-dark ${isOffcanvasOpen ? 'show' : ''}`}
              tabIndex="-1"
              id="offcanvasDarkNavbar"
              aria-labelledby="offcanvasDarkNavbarLabel"
              style={{ transform: isOffcanvasOpen ? 'translateX(0)' : 'translateX(100%)' }}

            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">
                  Aruwa
                </h5>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={() => setOffcanvasOpen(false)}
                ></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3 mb-3">
                  <li className="nav-item dropdown has-children">
                    <a
                      className="nav-link "
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      aria-current="page"
                    >
                      Services
                    </a>
                    <ul className="dropdown-menu mt-2 sub-menu">
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/full-home-interiors"
                          onClick={handleLinkClick}
                        >
                          Full Home Interior
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" onClick={handleLinkClick} to="/modular-interior-solution">
                          Modular Interirors
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/commercial-interior"
                          onClick={handleLinkClick}
                        >
                          Commercial Interiors
                        </Link>
                      </li>
                      <li><Link className="dropdown-item" to="/renovation-services-india">Rennovation Interior</Link></li>

                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" aria-current="page" to='/home-interior-design-ideas'>Design Gallery</Link>
                  </li>

                  <DropdownMenu handleLinkClick={handleLinkClick} ></DropdownMenu>

                  <li className="nav-item dropdown has-children">
                    <a
                      className="nav-link "
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      aria-current="page"
                    >
                      Resources
                    </a>
                    <ul className="dropdown-menu mt-2 sub-menu">
                      <li>
                        <Link className="dropdown-item" onClick={handleLinkClick} to="/blogs">
                          Blog
                        </Link>
                      </li>
                      {/* <li>
                        <Link className="dropdown-item" onClick={handleLinkClick} to="/magazine">
                          Magazine
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" onClick={handleLinkClick} to="/newsletter">
                          Newsletter
                        </Link>
                      </li> */}
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      onClick={handleLinkClick}
                      aria-current="page"
                      to="/contact-us"
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li className="nav-item dropdown has-children">
                    <a
                      className="nav-link "
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      aria-current="page"
                    >
                      More
                    </a>
                    <ul className="dropdown-menu mt-2 sub-menu">
                      <li>
                        <Link className="dropdown-item" onClick={handleLinkClick} to="/about-us">
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" onClick={handleLinkClick} to="/career">
                          Career
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" onClick={handleLinkClick} to="/become-franchisee">
                          Become Franchisee
                        </Link>
                      </li>
                    </ul>
                  </li>

                </ul>
                <Link to='/contact-us' className="btn btn-gray p-3">Get Free Quote</Link>
              </div>
            </div>
          </div>
        </nav>
      </footer>
    </>
  );
};

export default Footer;
