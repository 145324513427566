import React from 'react';
import Counter_section from '../components/Static-Section/Counter-section';
import WhyChoose from '../components/Static-Section/whyChoose';
import CityContact from '../components/CityContact';

const Franchise = () => {

    const whychoose = {
        'headering_before': 'Perks of Joining our Franchise Program! ',
        'features': [
          {
            'title': 'Reputed Brand',
            'desc': "In the years of service, we have established ourselves as a leading interior brand and have also flourished as a symbol of trust and loyalty in the sector of interior design. This provides an upper competitive edge for instant brand recognition in the market."
          },
          {
            'title': 'Guidance and Supervision',
            'desc': "We conduct extensive training programs for our franchise partners to guide them through our work process and help them maintain a constant quality of work. Our experienced team will help you with the tools and knowledge required to effectively run your franchise successfully."
          },
          {
            'title': 'Cost Advantages of Scale',
            'desc': "Aruwa’s operational efficiency and strong supplier relationships enable franchise holders to attain high-quality products in bulk and then sell them at competitive prices, thereby increasing profit margin for the owners."
          },
          {
            'title': 'Established Business Strategy',
            'desc': 'Join the ARUWA family and let us guide you on a journey towards success. Our tried-and-true business model is your compass, leading you to profitability while reducing the uncertainties of starting a new venture. We’re in this together.'
          },
          {
            'title': 'Versatility',
            'desc': "Embrace the freedom of running your own ARUWA franchise, while being backed by a system that’s been proven to work. It’s the best of both worlds - you get to make your own decisions, but with the reassurance of our support and guidance."
          },
          {
            'title': 'Advertising Benefits',
            'desc': "ARUWA has established itself as a phenomenal service provider in the interior design sector with a trusted customer base, reducing the cost of advertising required. Despite this, we have a dedicated digital marketing team as well."
          },
        ]
      };

    return (
        <>

            <section className='banner-simple'>
                <img src={process.env.PUBLIC_URL + '/asset/images/banner/franchise/franchise-banner.png'} className='banner-simple-back' />
                <div className='contianer-fluid h-100 p-3'>
                    <div className='row align-items-center justify-content-center h-100'>
                        <div className='col-12 text-center'>
                            <div className='banner-simple-text'>
                                <h1>
                                    Start <span className='yellow-text'>ARUWA</span> Interior Franchise Program Today!
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Counter_section />

            <section className='banner-simple'>
                <img src={process.env.PUBLIC_URL + '/asset/images/banner/franchise/boss.png'} className='banner-simple-back sm-not-absolute' />
                <div className='contianer-fluid h-100 py-5'>
                    <div className='row align-items-start justify-content-center h-100'>
                        <div className='col-12 text-center p-3'>
                                <h2>
                                Be Your Own  <span className='yellow-text'>Boss</span>
                                </h2>
                                <h5>Do you hold a passion for creating beautiful and functional space for homes and offices?<br/>
                                Do you wish to be a part of a growing and innovative interior design business?<br/>
                                If your answer is <span className='yellow-text'>YES</span>,<br/>
                                then we have a beautiful opportunity for you,<br/>
                                Let’s join our interior franchise program and be a part of something spectacular journey!</h5>
                        </div>
                    </div>
                </div>
            </section>

            <WhyChoose data={whychoose} />

            <CityContact/>

        </>
    );
 
}

export default Franchise;